// Tab1.js
import React from 'react';
import { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useEffect, useRef } from "react";
import { Card, Button, Grid, TextField, FormControl,Snackbar,Alert, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import { DialogContentText } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    createAdmissionPackage,retrieveAdmissionPackage,AutocompleteAdmissionPackage,UpdateAdmissionPackage,DeleteAdmissionPackage,StatusAdmissionPackage
  } from "../Apihelper/AdmissionCharges";
import json2mq from "json2mq";
const AdmissionCharges = (props) => {
    const [hasMore, setHasMore] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'
    const [fetchdatarow, setetchdatarow] = useState([]); // Default to 'success'
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    // Function to handle Snackbar opening
    const handleSnackbarOpen = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setOpenToast(true);
    };
  
    const [editItem, setEditItem] = useState({});
      const [modalMode, setModalMode] = useState('');
    //  modalMode
    const handleEditModalOpen = (id, name, duration, packageAmount, admissionValue) => {
        setModalMode('edit');
        setEditItem({
          id: id,
          name: name,
          duration: duration,
          packageAmount: packageAmount,
          admissionValue: admissionValue
        });
        setField(id)
        setField1(name || '');
        setField2(duration || '');
        setField3(packageAmount || '');
        setField4(admissionValue || '');
       setaddmodalopen(true); // Open the edit modal
      };
      
      const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
      const [status, setstatus] = useState('');
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [addmodalopen, setaddmodalopen] = useState(false);
    const [field, setField] = useState('');
    const [field1, setField1] = useState('');
    const [field2, setField2] = useState('');
    const [field3, setField3] = useState('');
    const [field4, setField4] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const handleToggle = (id,status) => {
    setOpenConfirmation(true);
   const newStatus = status === 1 ? 0 : 1;
  setstatus(newStatus);
  setDeleteConfirmationId(id);
  setIsActive(newStatus === 0);

  };

  const handleConfirm = async() => {
    // setIsActive(!isActive);
    // setOpenConfirmation(false);

    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; 
     
      
        const res = await StatusAdmissionPackage(userToken,userId,status, deleteConfirmationId);

        if (res.code == "200") {
            handleSnackbarOpen(res.message, 'success');
            setIsActive(!isActive);
            setOpenConfirmation(false);
            setOpenDeleteConfirmation(false);
            fetchData();
        } else {
            handleSnackbarOpen(res.message, 'error');
            //  console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
  };

  const handleCancel = () => {
    setOpenConfirmation(false);
  };

    const handleaddOpen = () => {
        setModalMode('add');
        setaddmodalopen(true);
    };
  
    const handleaddClose = () => {
      setField1(''); 
      setField2('');
      setField3('');
      setFormSubmitted(false);
        setaddmodalopen(false);
    };
  
    

    const [age, setAge] = React.useState('');
  
    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    const mediaQueryVar = useMediaQuery(
      json2mq({
        minWidth: 600,
      })
    );

    const handleDelete = (id) => {
        handleClose();
        setDeleteConfirmationId(id);
        setOpenDeleteConfirmation(true,id);
      };
    
      const handleConfirmDelete =async (id) => {
        // setSnackbarMessage('package deleted successfully');
        // setSnackbarSeverity('error');
        // setOpenDeleteConfirmation(false);
        // setOpenToast(true); // Show toast message

        if (deleteConfirmationId !== null) {
         
            try {
                const userData = localStorage.getItem("userdata");
                const userDataObj = JSON.parse(userData);
                const userToken = userDataObj.token;
                const userId = userDataObj.id; 
             
              
                const res = await DeleteAdmissionPackage(userToken, userId, deleteConfirmationId);
        
                if (res.code == "200") {
                    handleSnackbarOpen(res.message, 'success');
                    setOpenDeleteConfirmation(false);
                    fetchData();
                } else {
                    handleSnackbarOpen(res.message, 'error');
                    //  console.error(error); // Handle error
                }
            } catch (error) {
                console.error(error);
                // Handle errors
            }
        }
        };
      
    
      const handleCancelDelete = () => {
        setOpenDeleteConfirmation(false);
      };
      const handleToastClose = () => {
        setOpenToast(false);
      };
    
  
    
 
    
      const ITEM_HEIGHT = 48;
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

 

   
const handleSubmit = async () => {
  setFormSubmitted(true); // Set form as submitted

  if (!field1 || !field2 || !field3 ) {
    // Prevent submission if any field is empty
    return;
  }
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
const userToken = userDataObj.token;
 
    if (modalMode === 'add') {
      try {
        const res = await createAdmissionPackage(userToken, field1, field2, field3);
        if (res.code == "200") {
             setField1(''); 
             setField2('');
             setField3('');
            
            
            handleSnackbarOpen(res.message, 'success');
            fetchData();
          } else {
            handleSnackbarOpen(res.message, 'error');
         //   console.error(error); // Handle error
          }
      } catch (error) {
        console.error(error); // Handle error
      }
    } else if (modalMode === 'edit') {
        try {
            if (editItem) {
                const { id, name, duration, packageAmount, admissionValue } = editItem;
               
            const res = await UpdateAdmissionPackage(id,userToken,  field1, field2, field3);
            if (res.code == "200") {
                 setField1(''); 
                 setField2('');
                 setField3('');
               
                
                handleSnackbarOpen(res.message, 'success');
                fetchData();
              } else {
                handleSnackbarOpen(res.message, 'error');
             //   console.error(error); // Handle error
              }
            }
          } catch (error) {
            console.error(error); // Handle error
          }
    }
    
    // Close the modal after submission
    handleaddClose();
  };
  const fetchData = async (value = '') => {
    setLoading(true);
    setHasMore(true);
    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text
        const limit = 10; // Example limit
        const index = 0; // Example index
        const res = await retrieveAdmissionPackage(userToken, userId, searchText, limit, index);

        if (res.code == "200") {
            setetchdatarow(res.load_data)
            console.log(res.load_data);
            setLoading(false);
        } else {
            handleSnackbarOpen(res.message, 'error');
            // console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
};

  useEffect(() => {
   
    fetchData();
}, []); // This effect runs only once after the component mounts
const handleLoadMore = async () => {
    // Calculate the next index based on the number of items loaded so far
    const nextIndex = fetchdatarow.length
  
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
  
    try {
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const searchText = ''
      const limit = 10;
  
      const res = await retrieveTrainerPackage(userToken, userId, searchText, limit, nextIndex);
  
      if (res.code == "200") {
        // Append the newly loaded data to the existing data
        setetchdatarow((prevResults) => [...prevResults, ...res.load_data]);
  
        // Check if there are more results available
        if (res.load_data.length >= 0 && res.load_data.length < 10) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSearchChange = (event, value) => {
    const searchText = value || ''; // If value is undefined or empty, set searchText to empty string
    // setSearchText(searchText);
    // Call fetchData function here with the updated searchText
    fetchData(searchText);
  };


const handleInputChange = async (value) => {
    if (value.length >= 3) {
    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text
      
        const res = await AutocompleteAdmissionPackage(userToken, userId, searchText);

        if (res.code == "200") {
            const names = res.data.map(item => item.name);
            setOptions(names);
        } else {
            handleSnackbarOpen(res.message, 'error');
            //  console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
}
};
  

  return (
    <div>
    <Box sx={{ display: 'flex',alignItems: 'center' }}>
              <Box>
              <Autocomplete
    freeSolo
    id="free-solo-2-demo"
    disableClearable
    options={options}
    onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
    onChange={(event, value) => handleSearchChange(event, value)}
    renderInput={(params) => (
      
      <TextField
        {...params}
        size="small"
        sx={{
          width: 300,
          '& .MuiInputLabel-root': {
            textAlign: 'center', // Center align the label text
          },
        }}
        label="Search input"
        InputProps={{
          ...params.InputProps,
          type: 'search',
        }}
      />
    )}
    sx={{
      '& .MuiInputBase-root': {
        alignItems: 'center',
        height: '40px',
      },
    }}
  />
              </Box>
              

            
              <Box sx={{ marginLeft: 'auto' }}>
                <Button variant="contained" startIcon={<ControlPointIcon />}  onClick={handleaddOpen}  sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }}>Add New
                </Button>
              </Box>

           
            </Box>


            <TableContainer
    component={Paper}
    sx={{
      boxShadow: "0px 12px 30px rgba(0, 0, 0, 0.2)",
      borderRadius: "15px",
      overflow: "hidden",
      margin: "20px 0",
      background: "linear-gradient(135deg, #ffe0f7 30%, #e0f7fa 100%)", // Light pink and teal gradient background
    }}
  >
    {loading ? (
      <div style={{ textAlign: "center", margin: "50px auto" }}>
        <CircularProgress />
      </div>
    ) : (
      <Table size="small" aria-label="enhanced table">
        <TableHead>
          <TableRow
            sx={{
              background: "linear-gradient(135deg, #e91e63 30%, #ff80ab 100%)", // Pink gradient for the header
            }}
          >
            {["Name", "Duration (DAYS)", "Total Value", "Status", "Action"].map(
              (header) => (
                <TableCell
                  key={header}
                  align="left"
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    borderBottom: "none",
                    fontSize: "1rem",
                    letterSpacing: "0.5px",
                  }}
                >
                  {header}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchdatarow.map((row, indexx) => (
            <TableRow
              key={indexx}
              sx={{
                "&:nth-of-type(odd)": {
                  backgroundColor: "rgba(224, 247, 250, 0.8)",
                }, // Light teal for odd rows
                "&:nth-of-type(even)": {
                  backgroundColor: "rgba(255, 224, 247, 0.8)",
                }, // Light pink for even rows
                "&:hover": {
                  backgroundColor: "#ffe0b2", // Light orange hover effect
                  cursor: "pointer",
                },
                transition: "background-color 0.3s ease",
              }}
            >
              <TableCell>
                <Typography
                  sx={{
                    color: "#333333",
                    fontWeight: 600,
                    fontSize: "0.95rem",
                  }}
                >
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    color: "#333333",
                    fontWeight: 600,
                    fontSize: "0.95rem",
                  }}
                >
                  {row.duration}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    color: "#333333",
                    fontWeight: 600,
                    fontSize: "0.95rem",
                  }}
                >
                  {row.package_amount}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Button
                  variant="outlined"
                  sx={{
                    fontWeight: 700,
                    textTransform: "none",
                    borderRadius: "20px",
                    borderColor:
                      row.status === 1 ? "#43a047" : "#e53935", // Green for active, red for inactive
                    color: row.status === 1 ? "#43a047" : "#e53935",
                    "&:hover": {
                      backgroundColor: row.status === 1 ? "#e8f5e9" : "#ffebee",
                      borderColor:
                        row.status === 1 ? "#388e3c" : "#d32f2f", // Darker hover colors
                    },
                  }}
                  onClick={() => handleToggle(row.id, row.status)}
                >
                  {row.status === 1 ? "Active" : "Inactive"}
                </Button>
              </TableCell>
              <TableCell>
                <>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() =>
                      handleEditModalOpen(
                        row.id,
                        row.name,
                        row.duration,
                        row.package_amount
                      )
                    }
                    sx={{ "&:hover": { color: "#c2185b" } }} // Hover effect for edit button (dark pink)
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => handleDelete(row.id)}
                    sx={{ "&:hover": { color: "#b71c1c" } }} // Hover effect for delete button
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )}
  </TableContainer>
             <div style={{ textAlign: "center", marginTop: "10px" }}>
             <Button
  variant="contained"
  color="primary"
  onClick={handleLoadMore}
 
  disabled={!hasMore || (fetchdatarow.length < 10 && fetchdatarow.length !== 0)}
  sx={{ fontWeight: 'bold', textTransform: 'none' }}
>
  {fetchdatarow.length === 0 || fetchdatarow.length < 10 ? 'No More Records' : 'Load More'}
</Button>


      </div>
      <Dialog open={addmodalopen} maxWidth="xs" onClose={handleaddClose}>
        <DialogTitle>{modalMode === 'add' ? 'Add Admission Charges' : 'Edit  Admission Charges'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            type="text"
            value={field1}
            onChange={(e) => setField1(e.target.value.toUpperCase())}
            error={formSubmitted && !field1} // Show error if form submitted and field1 is empty
            helperText={formSubmitted && !field1 ? "Name is required" : ""} // Show error message only after submit click
           
          />
          <TextField
            margin="dense"
            label="Duration (Days)"
            fullWidth
            type="number"
            value={field2}
            error={formSubmitted && !field2} // Show error if form submitted and field1 is empty
            helperText={formSubmitted && !field2 ? "Duration is required" : ""} // Show error message only after submit click
          
         
            onChange={(e) => setField2(Math.max(0, e.target.value))}
          />
          <TextField
            margin="dense"
            label="Total Value"
            fullWidth
            type="number"
            value={field3}
           
            error={formSubmitted && !field3}
            helperText={formSubmitted && !field3 ? "Total Value is required" : ""}
          
           
            onChange={(e) => setField3(Math.max(0, e.target.value))}
          />
        
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontWeight: 'bold', textTransform: 'none' }}  startIcon={<CancelIcon />}onClick={handleaddClose}>Cancel</Button>
          <Button sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<CheckCircleIcon />}  onClick={handleSubmit} variant="contained" color="primary">
            {modalMode === 'add' ? 'Submit' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog open={openDeleteConfirmation} onClose={handleCancelDelete}>
      
  <DialogTitle>Delete Confirmation</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to delete this package?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button startIcon={<CancelIcon />} sx={{fontWeight:'bold',textTransform:'none'}} onClick={handleCancelDelete}>Cancel</Button>
    <Button onClick={handleConfirmDelete}  sx={{fontWeight:'bold',textTransform:'none'}} variant="contained" startIcon={<DeleteIcon />} color="error"> Delete</Button>
  </DialogActions>
</Dialog>


      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert  variant="filled" onClose={handleToastClose} severity={snackbarSeverity}>
        {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmation} onClose={handleCancel}>
        <DialogTitle>{isActive ? 'Deactivate' : 'Activate'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isActive
              ? 'Are you sure you want to deactivate this item?'
              : 'Are you sure you want to activate this item?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  sx={{fontWeight:'bold',textTransform:'none'}} startIcon={<CancelIcon />}onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm }sx={{fontWeight:'bold',textTransform:'none'}} variant="contained"startIcon={<CheckCircleIcon />} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdmissionCharges;
