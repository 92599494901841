import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createAdminUser } from "../Apihelper/ApiHelperUser";
import CancelIcon from "@mui/icons-material/Cancel";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Card,
  Snackbar,
  Alert,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Menu from "@mui/material/Menu";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";

import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function AddUser(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const dateRef = useRef();

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [UseradminData, setUseradminData] = useState({
    username: "",
    fullname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    userType: "",
  });
  const [errors, setErrors] = useState({});

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseradminData({
      ...UseradminData,
      [name]: name === "email" ? value.toLowerCase() : (name === "userType" ? value : value.toUpperCase()),
    });
  };
  
  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!UseradminData.username) newErrors.username = "Username is required";
    if (!UseradminData.fullname) newErrors.fullname = "Full name is required";
    if (!UseradminData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(UseradminData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!UseradminData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(UseradminData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!UseradminData.password) {
      newErrors.password = "Password is required";
    } else if (UseradminData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (UseradminData.password !== UseradminData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if there are no errors
  };
  const handleSubmit = async () => {
    if (!validate()) {
      handleSnackbarOpen("Please fix the errors in the form", "error");
      return; // Exit the function if validation fails
    }
    // return false;
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await createAdminUser(userToken, UseradminData);
      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setUseradminData({
          username: "",
          fullname: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
          userType: "",
        });
        setTimeout(() => {
          navigate("/userlist");
        }, 3000);
      } else {
        handleSnackbarOpen(res.message, "error");
        //   console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  const clearForm = () => {
    setUseradminData({
      username: '',
      fullname: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      userType: '',
    });
    setErrors({}); // Optionally clear errors as well
  };
  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <Header />
      <CssBaseline />
      <Box
        component="main"
        sx={{ flexGrow: 1, overflowY: "auto", padding: "20px" }}
      >
        <div style={{ display: "flex", flexGrow: 1, marginTop: "50px" }}>
          <div style={{ flex: 1, marginTop: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* First table in the first row */}
            
              <Box sx={{ display: "flex", alignItems: "center" }}>
    <Button
      variant="contained"
      startIcon={<ArrowBackIcon />}
      sx={{
        textTransform: "none",
        fontWeight: 700,
        marginRight: 2, // Adjust this value as needed
      }}
      onClick={() => navigate(-1)}
    >
      Go Back
    </Button>
  
    <Typography variant="h5" component="h6">
                Add User
              </Typography>
  </Box>
  
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
                onClick={() => navigate("/userlist")}
              >
                View List
              </Button>
            </Box>

            <Card sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid
                container
                spacing={3}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="username"
                    label="User Name"
                    fullWidth
                    placeholder=" Enter Name"
                    value={UseradminData.username}
                    onChange={handleChange}
                    error={!!errors.username}
                    helperText={errors.username}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="fullname"
                    label="Name"
                    fullWidth
                    placeholder=" Enter Name"
                    value={UseradminData.fullname}
                    onChange={handleChange}
                    error={!!errors.fullname}
                    helperText={errors.fullname}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="phone"
                    label="Phone"
                    type="number"
                    fullWidth
                    placeholder="Enter Phone..."
                    value={UseradminData.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="email"
                    label="Email"
                    fullWidth
                    placeholder=" Enter Email"
                    value={UseradminData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ minWidth: 120 }} fullWidth error={!!errors.userType}>
                    <InputLabel id="demo-simple-select-helper-label">
                      User Type
                    </InputLabel>
                    <Select
                      fullWidth
                      sx={{ minWidth: 120, height: "50px" }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={UseradminData.userType}
                      name="userType"
                      label="User Type"
                      onChange={handleChange}
                      
                    >
                      <MenuItem value="">Select User Type</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="editor">Editor</MenuItem>
                      <MenuItem value="viewer">viewer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="password"
                    label="Password"
                    fullWidth
                    placeholder="Enter Password"
                    type="password"
                    value={UseradminData.password}
                    error={!!errors.password}
                    helperText={errors.password}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="confirmPassword"
                    label="Confirm Password"
                    fullWidth
                    placeholder="Enter Password"
                    type="password"
                    value={UseradminData.confirmPassword}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={clearForm}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                  type="reset"
                  startIcon={<CancelIcon />}
                >
                  Clear
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                  variant="contained"
                  startIcon={<CheckCircleIcon />}
                  onClick={handleSubmit}
                >
                 Submit
                </Button>
              </Box>
            </Card>
          </div>
        </div>
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AddUser;
