import "./ManagePackage.css";
import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import MembershipPackage from './MembershipPackage';
import PtPackage from './PtPackage';
import AdmissionPackage from './AdmissionPackage'; // Import the new component
import AdmissionCharges from './AdmissionCharges'; // Import the new component

import { useNavigate } from "react-router-dom";

const drawerWidth = 292;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
  
function ManagePackage() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      navigate('/');
    }
  }, []);

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );

  const tabStyle = (isActive) => ({
    textDecoration: 'none',
    flexGrow: 1,
    textTransform: 'none',
    fontWeight: 'bold',
    marginRight: '10px',
    backgroundColor: isActive ? '#D8E2FF' : 'inherit',
    borderRadius: "10px 10px 0px 0px",
    borderBottom: isActive ? "2px solid #005AC1" : 'none',
    color: isActive ? "#005AC1" : 'inherit',
  });

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box>
        <Header />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px' }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ borderBottom: 1, marginLeft: '25px', borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChangetab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab sx={{ ...tabStyle(0 === value) }} label="Membership Package" {...a11yProps(0)} />
                  <Tab sx={{ ...tabStyle(1 === value) }} label="Personal Tr. Package" {...a11yProps(1)} />
                  <Tab sx={{ ...tabStyle(2 === value) }} label="Admission Package" {...a11yProps(2)} /> {/* New Tab */}
                  <Tab sx={{ ...tabStyle(3 === value) }} label="Admission Membership Package" {...a11yProps(3)} /> {/* New Tab */}
                </Tabs>
              </Box>
            </Box>

            <TabPanel value={value} index={0}>
              <MembershipPackage />
            </TabPanel>
          
            <TabPanel value={value} index={1}>
              <PtPackage />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <AdmissionPackage /> {/* New Component */}
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AdmissionCharges /> {/* New Component */}
            </TabPanel>
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default ManagePackage;
