import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createAdminUser } from "../Apihelper/ApiHelperUser";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { useEffect, useRef } from "react";
import {
  Card,
  Snackbar,
  Alert,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Menu from "@mui/material/Menu";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";

import { createOfferPackage } from "../Apihelper/ApiPackage";

import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function AddOfferPackage(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dateRef = useRef();

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [Dropdowndata, setDropdowndata] = useState([]); // Default to 'success'

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [UseradminData, setUseradminData] = useState({
    fullname: "",
    value: "",
    description: "",
    quantity: "",
    duration: "",
  });
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseradminData({
      ...UseradminData,
      [name]: value.toUpperCase(), // Converts all values to uppercase
    });
  };
  

  const handleSubmit = async () => {
    // console.log(UseradminData);
    setSubmitted(true);
    // console.log(UseradminData);
    const requiredFields = [
      "fullname",
      "value",
      "description",
      "quantity",
      "duration",
    ];
    const emptyFields = requiredFields.filter((field) => !UseradminData[field]);
    if (emptyFields.length > 0) {
      // Show error for empty fields

      return;
    }
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await createOfferPackage(userToken, UseradminData);
      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setUseradminData({
          fullname: "",
          value: "",
          description: "",
          quantity: "",
          duration: "",
        });
       
        navigate("/ParentOfferPackage", { state: { tabIndex: 1 } });
      } else {
        handleSnackbarOpen(res.message, "error");
        //   console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };
  const fetchData = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await AutoNonMembershipNumber(userToken, userId);

      if (res.code == "200") {
        setUseradminData({ ...UseradminData, membershipnumber: res.data });
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const fetchDropdowndata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await offerPackageDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdowndata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchDropdowndata();
    fetchData();
  }, []);
  const handlepayblevalueChange = async (e) => {
    // Update state with the selected package
    const selectedPackageId = e.target.value;
    setUseradminData({ ...UseradminData, package: selectedPackageId });
  };
  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <Header />
      <CssBaseline />
      <Box
        component="main"
        sx={{ flexGrow: 1, overflowY: "auto", padding: "20px" }}
      >
        <div style={{ display: "flex", flexGrow: 1, marginTop: "50px" }}>
          <div style={{ flex: 1, marginTop: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
    <Button
      variant="contained"
      startIcon={<ArrowBackIcon />}
      sx={{
        textTransform: "none",
        fontWeight: 700,
        marginRight: 2, // Adjust this value as needed
      }}
      onClick={() => navigate(-1)}
    >
      Go Back
    </Button>
    <Typography variant="h6" component="h6">
    Add Offer Package
    </Typography>
  </Box>
  
           
              {/* Second table in the first row */}
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
                onClick={() => navigate("/ParentOfferPackage")}
              >
                View List
              </Button>
            </Box>

            <Card sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid
                container
                spacing={3}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="fullname"
                    label="Name"
                    fullWidth
                    placeholder=" Enter Name"
                    value={UseradminData.fullname}
                    onChange={handleChange}
                    error={submitted && !UseradminData.fullname}
                    helperText={
                      submitted &&
                      !UseradminData.fullname &&
                      "Name  is required"
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="value"
                    label="Enter value"
                    type="number"
                    fullWidth
                    placeholder=" Enter 
                    value here"
                    value={UseradminData.value}
                    onChange={handleChange}
                    error={submitted && !UseradminData.value}
                    helperText={
                      submitted && !UseradminData.value && "value  is required"
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="description"
                    label="Description"
                    fullWidth
                    placeholder="Enter Description..."
                    value={UseradminData.description}
                    onChange={handleChange}
                    error={submitted && !UseradminData.description}
                    helperText={
                      submitted &&
                      !UseradminData.description &&
                      "Description  is required"
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="quantity"
                    label="Quantity"
                        type="number"
                    fullWidth
                    placeholder=" Enter Quantity"
                    value={UseradminData.quantity}
                    onChange={handleChange}
                    error={submitted && !UseradminData.quantity}
                    helperText={
                      submitted &&
                      !UseradminData.quantity &&
                      "Quantity  is required"
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="duration"
                    label="Duration"
                        type="number"
                    fullWidth
                    placeholder=" Enter Duration"
                    value={UseradminData.duration}
                    onChange={handleChange}
                    error={submitted && !UseradminData.duration}
                    helperText={
                      submitted &&
                      !UseradminData.duration &&
                      "Duration  is required"
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              ></Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                  type="reset"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<CheckCircleIcon />}
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Add Package
                </Button>
              </Box>
            </Card>
          </div>
        </div>
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AddOfferPackage;
