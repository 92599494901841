import React, { useRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import styles from './Invoice.module.css';
import { getinvoiceBYid } from "../Apihelper/Dashboard";
import { useNavigate } from 'react-router-dom'; // Make sure to import the useNavigate hook
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Container,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
} from '@mui/material';
const InvoicePage = () => {
  const componentRef = useRef();
  const location = useLocation();
 
  const [loading, setLoading] = useState(false);
  const [fetchdatarow, setFetchdatarow] = useState([]); // Updated variable name
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const invoice_number = location.state?.invoice_number;


  const conversation_id = location.state?.conversation_id;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice_${fetchdatarow?.membership_number || ''}`,
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (invoice_number) {
      fetchData(invoice_number);
    }
  }, [invoice_number]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;

      const res = await getinvoiceBYid(userToken, userId, invoice_number);
      if (res.code == "200") {
        setFetchdatarow(res.data);
      } else {
        handleSnackbarOpen(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
      handleSnackbarOpen('An error occurred while fetching data.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleGoBack = () => {
    navigate(-1); // Navigates back one step in the browser history
  };
  return (
    <Container
    maxWidth="md"
    style={{ 
      padding: '20px', 
      backgroundColor: '#e7f3fe', // Light blue background color
      borderRadius: '8px',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    }}
  >
   <Button
  variant="contained"
  color="secondary"
  onClick={() => handleGoBack(conversation_id)} // Pass the id here
  style={{ marginBottom: '16px' }} // Apply the mb: 2 margin (equivalent to 16px)
  startIcon={<ArrowBackIcon />} // Add the back arrow icon
>
  Go Back
</Button>
    {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    ) : (
      <Box
        ref={componentRef}
        style={{
          backgroundColor: '#fff', // White background for the invoice itself
          borderRadius: '12px',
          padding: '30px',
          
          fontFamily: '"Roboto", sans-serif',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        
        <Box mb={2} textAlign="left">
            
          </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Box>
            <img src="images/rectangle-9@2x.png" alt="Gym Logo" style={{ width: '160px' }} />
          </Box>
          <Box textAlign="right">
            <Typography variant="h4" style={{ color: '#0077b6' }}>MAA TARATARINI MULTI GYM</Typography>
            <Typography variant="subtitle1" style={{ color: '#333' }}>
              PLOT NO.167 Canal Road, Bomikhal Bhubanewar, 751010
            </Typography>
            <Typography variant="body2" style={{ color: '#777' }}>
              Email: sagarparida29@gmail.com | Phone: +91 9937542268
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" style={{ fontWeight: 'bold', textAlign: 'center', color: '#0077b6' }} gutterBottom>
          Invoice
        </Typography>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <Typography variant="body1">
            <strong>Invoice No:</strong> {fetchdatarow?.invoice_number ? `INV${fetchdatarow.invoice_number}` : 'N/A'}
          </Typography>
          <Typography variant="body1">
            <strong>Date:</strong> {fetchdatarow?.date || 'N/A'}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <Typography variant="body1">
            <strong>Member Name:</strong> {fetchdatarow?.name || 'N/A'}
          </Typography>
          <Typography variant="body1">
            <strong>Phone Number:</strong> {fetchdatarow?.phone || 'N/A'}
          </Typography>
        </Box>

        <Typography variant="body1" mb={3}>
          <strong>Membership ID:</strong> {fetchdatarow?.membership_number || 'N/A'}
        </Typography>

        <Box mt={4}>
          <Typography variant="h6" style={{ fontWeight: 'bold', color: '#0077b6' }}>Package Details</Typography>
          <Table style={{ marginTop: '10px', backgroundColor: '#f9f9f9' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#0077b6', color: '#fff' }}>Package</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#0077b6', color: '#fff' }}>Package Value</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#0077b6', color: '#fff' }}>Payable Amount</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#0077b6', color: '#fff' }}>Paid Amount</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#0077b6', color: '#fff' }}>Due Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{fetchdatarow?.package_name || 'N/A'}</TableCell>
                <TableCell>{fetchdatarow?.package_value || 'N/A'}</TableCell>
                <TableCell>{fetchdatarow?.payable_amount || 'N/A'}</TableCell>
                <TableCell>{fetchdatarow?.paying_amount || 'N/A'}</TableCell>
                <TableCell>{fetchdatarow?.due_amount || 'N/A'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box mt={4} textAlign="center" style={{ padding: '20px', backgroundColor: '#e0f7fa', borderRadius: '8px' }}>
          <Typography variant="body1" style={{ color: '#555' }}>
            MAA TARATARINI MULTI GYM is a cutting-edge functional fitness system that can help everyday men.
          </Typography>
        </Box>
      </Box>
    )}

    <Box mt={4} textAlign="center">
      <Button
        variant="contained"
        color="primary"
        onClick={handlePrint}
        style={{ padding: '10px 20px', fontSize: '16px' }}
      >
        Print / Download Invoice
      </Button>
    </Box>
  </Container>
  );
};

export default InvoicePage;
