import React, { useState,useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
  TextField, Grid, FormControl, InputLabel, Select, MenuItem,Snackbar,Alert,
  CircularProgress
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
   
  PackageDropdownafteradmission,
    MembeChangePackage,
    ChangePackageDropdownValue,
  } from "../../Apihelper/MemberApiHelpr";

  import {
    PackageadmissionDropdown,
    PackageAdmissionDropdownvalue,
  } from "../../Apihelper/AdmissionValueApi.js";
const ChangePackage = ({ open, onClose, packageData,member_id,fetchData }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
};
const formatDateToYMD = (date) => {
  if (!date) return getCurrentDate(); // Return the current date if the input is empty or invalid

  const [day, month, year] = date.split('-'); // Split the d-m-y format
  const formattedDate = new Date(`${year}-${month}-${day}`); // Create a Date object
  return formattedDate.toISOString().split('T')[0]; // Format to 'yyyy-mm-dd' and return
};

const getMinStartDate = () => {
  console.log(packageData?.start_date);
  return packageData?.start_date
    ? formatDateToYMD(packageData.start_date)
    : getCurrentDate();
};
  const [formdueData, setFormdueData] = useState({
    member_id:'',
    package_id: '',
    total_payble_amount: '',
    payble_amount: '',
    offer: '',
    paying_amount: '',
    mode_of_payment: '',
    date_of_payment: getCurrentDate(),
    start_date: '', // Fallback to current date
   
   
    membershipvalue:"",
     membership:"",
  });
  const initializeState = async () => {
    const formattedStartDate = formatDateToYMD(packageData?.start_date); // Use the function to get the formatted date
    setFormdueData((prevData) => ({
      ...prevData,
      start_date: formattedStartDate, // Set the formatted date in the state
    }));
  };

  // useEffect to initialize state when the component mounts
  useEffect(() => {
    initializeState();
  }, []); // Empty dependency array to run only once when component mounts
  console.log(formdueData.start_date);
  const [renwalloading, setrenwalLoading] = useState(false);
  const [Dropdowndata, setDropdowndata] = useState([]); // Default to 'success'
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [packageamount, setpackageamount] = useState(""); // Default to 'success'
  const [Dropdowndatamember, setDropdowndatamember] = useState([]);
  const [Dropdownadmissiondata, setDropdownadmissiondata] = useState([]); // Default to 'success'
  // const handlepayblevalueChangemember = async (e) => {
  //   const selectedPackageId = e.target.value;
  //   const selectedPackage = Dropdowndata.find(
  //     (row) => row.id === selectedPackageId
  //   );

  //   setFormdueData({
  //     ...formdueData,
  //     package_id: selectedPackageId,
  //     packagename: selectedPackage?.name || "",
  //   });

  //   try {
  //     const userData = localStorage.getItem("userdata");
  //     const userDataObj = JSON.parse(userData);
  //     const userToken = userDataObj.token;
  //     const userId = userDataObj.id;
  //     const res = await ChangePackageDropdownValue(
  //       userToken,
  //       userId,

  //       selectedPackageId,
  //       member_id,
  //     );

  //     if (res.code == "200") {
  //       // Ensure payble_amount and total_payble_amount are the same initially
  //       const packageAmount = res.data.package_amount;
  //       setFormdueData((prevState) => ({
  //         ...prevState,
  //         total_payble_amount: packageAmount,
  //         payble_amount: packageAmount, // Set both to the same value initially
  //       }));
  //     } else {
  //       console.error("API error:", res.message);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormdueData({
  //     ...formdueData,
  //     [name]: value
  //   });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormdueData((prevData) => {
      let updatedData = { ...prevData, [name]: value };

      // Ensure 'payble_amount' starts as the same as 'total_payble_amount'
      if (!prevData.payble_amount) {
        updatedData.payble_amount = prevData.total_payble_amount;
      }

      // If 'offer' changes, update the payable amount based on the total payable amount and the offer
      if (name === "offer") {
        const updatedPayable = prevData.total_payble_amount - value;
        updatedData.paying_amount = updatedPayable >= 0 ? updatedPayable : 0;
      }

      if (name === "payble_amount") {
        updatedData.payble_amount = prevData.total_payble_amount; // Keep payble_amount same as total_payble_amount
      }
      return updatedData;
    });
  };

  const handleChangePaymentMode = (e) => {
    setFormdueData({
      ...formdueData,
      mode_of_payment: e.target.value
    });
  };

//   const handleSave = async () => {
//     setRenwalLoading(true);
//     try {
//       const response = await fetch('/api/renew-package', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(formdueData)
//       });
  
//       if (response.ok) {
//         const result = await response.json();
//         console.log('Package renewed successfully:', result);
//         fetchPackages(); // Refresh packages after successful save
//         onClose(); // Close the dialog
//       } else {
//         console.error('Failed to renew package:', response.statusText);
//       }
//     } catch (error) {
//       console.error('Error renewing package:', error);
//     } finally {
//       setRenwalLoading(false);
//     }
//   };


  const handleSave = async () => {
    setrenwalLoading(true); // Show loader when form submission starts
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const response = await MembeChangePackage(
        userToken,
        userId,
        member_id,
        formdueData
      );

      if (response.code == "200") {
        setSnackbarMessage(response.message);
        setSnackbarSeverity("success");
        setOpenToast(true); // Show success Snackbar
        onClose(); // Close the modal
        fetchData();
        setFormdueData({
          member_id: member_id,
          package_id: "",
          total_payble_amount: "",
           payble_amount: "",
           offer: "",
           paying_amount: "",
           mode_of_payment: "",
           date_of_payment: "",
           start_date: "",
           end_date: "",
           membershipvalue:"",
           membership:""
        });
      } else {
        setSnackbarMessage(
          response.message || "Error occurred while renewing package."
        );
        setSnackbarSeverity("error");
        setOpenToast(true); // Show error Snackbar
      }
    } catch (error) {
      setSnackbarMessage("Failed to renew package: " + error.message);
      setSnackbarSeverity("error");
      setOpenToast(true); // Show error Snackbar
    } finally {
      setrenwalLoading(false); // Hide loader when submission finishes
    }
  };
  const fetchDropdowndatarenwal = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageDropdownafteradmission(userToken, userId);

      if (res.code == "200") {
        setDropdowndatamember(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchDropdowndatarenwal();
    fetchDropdownAdmissiondata();
  }, []);
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const fetchDropdownAdmissiondata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageadmissionDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdownadmissiondata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const [prevPackageAmount, setPrevPackageAmount] = useState(0);
  const handlepayblevalueChangemember = async (e) => {
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdowndatamember.find(
      (row) => row.id === selectedPackageId
    );
  
    setFormdueData((prevState) => ({
      ...prevState,
      package_id: selectedPackageId,
      packagename: selectedPackage?.name || "",
    }));
  
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
  
      const res = await ChangePackageDropdownValue(
        userToken,
        userId,
        selectedPackageId,
        member_id,
       
      );
  
      if (res.code == "200") {
        const packageAmount = res.data.package_amount || 0;
  
        setFormdueData((prevState) => {
          // Subtract the previous amount and add the new one
          const total_payble_amount =
            (parseFloat(prevState.total_payble_amount) || 0) - prevPackageAmount + packageAmount;
  
          return {
            ...prevState,
            total_payble_amount,
            payble_amount: total_payble_amount, // Sync with total_payble_amount
          };
        });
  
        // Update the previous amount to the current selection's amount
        setPrevPackageAmount(packageAmount);
      } else {
        console.error("API error:", res.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [prevAdmissionAmount, setPrevAdmissionAmount] = useState(0);
  const handlepayblevalueadmissionChange = async (e) => {
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdownadmissiondata.find(
      (row) => row.id === selectedPackageId
    );
  
    setFormdueData({
      ...formdueData,
      membership: selectedPackageId,
      membership_packagename: selectedPackage?.name || "",
    });
  
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
  
      const res = await PackageAdmissionDropdownvalue(
        userToken,
        userId,
        selectedPackageId
      );
  
      if (res.code == "200") {
        const packageAmount = parseFloat(res.data) || 0;
  
        setFormdueData((prevState) => {
          // Subtract the previous admission amount and add the new one
          const total_payble_amount =
            (parseFloat(prevState.total_payble_amount) || 0) - prevAdmissionAmount + packageAmount;
  
          return {
            ...prevState,
            membershipvalue: packageAmount,
            total_payble_amount,
            payble_amount: total_payble_amount, // Sync with total_payble_amount
          };
        });
  
        // Update the previous admission amount to the current selection's amount
        setPrevAdmissionAmount(packageAmount);
      } else {
        console.error("Error response:", res.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  
  
  
  return (
    <>
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Change Package</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6} sx={{ marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="package-label">Package</InputLabel>
              <Select
                  labelId="package-label"
                  id="package"
                  label="Package"
                  value={formdueData.package_id}
                  onChange={handlepayblevalueChangemember}
                  fullWidth
                >
                  {Dropdowndatamember.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>
          </Grid>
          {packageData && packageData.admission_expired && (
  <>
    <Grid item xs={6} sx={{ marginTop: "20px" }}>
      <FormControl fullWidth>
        <InputLabel id="membership-label">Membership</InputLabel>
        <Select
          labelId="membership-label"
          id="membership"
          label="Membership"
          value={formdueData.membership}
          onChange={handlepayblevalueadmissionChange}
          placeholder="Choose Membership"
          fullWidth
        >
          {Dropdownadmissiondata.map((row) => (
            <MenuItem key={row.id} value={row.id}>
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={6} sx={{ marginTop: "20px" }}>
      <TextField
      fullWidth
       labelId="membership-Value"
          id="membership-value"
        label="Membership Value"
        inputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
       
        value={formdueData.membershipvalue}
        onChange={(e) =>
          setFormdueData({
            ...formdueData,
            membershipvalue: e.target.value,
          })
        }
      />
    </Grid>
  </>
)}


          <Grid item xs={6} sx={{ marginTop: "20px" }}>
            <TextField
              label="Total Payable Amount"
              fullWidth
              name="total_payble_amount"
              value={formdueData.total_payble_amount}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="number"
              label="Payable Amount"
              fullWidth
              name="payble_amount"
              value={formdueData.payble_amount}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Offer"
              fullWidth
              name="offer"
              value={formdueData.offer}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="number"
              label="Paying Amount"
              fullWidth
              name="paying_amount"
              value={formdueData.paying_amount}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 120 }} fullWidth>
              <InputLabel id="mode-of-payment-label">
                Mode of Payment
              </InputLabel>
              <Select
                fullWidth
                sx={{ minWidth: 120, height: "50px" }}
                labelId="mode-of-payment-label"
                id="mode-of-payment"
                label="Mode of Payment"
                value={formdueData.mode_of_payment}
                name="mode_of_payment"
                onChange={handleChangePaymentMode}
              >
                <MenuItem value="paytm">Paytm</MenuItem>
                <MenuItem value="googlepay">Google Pay</MenuItem>
                <MenuItem value="phonepe">PhonePe</MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="card"> Card</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Date of Payment"
              fullWidth
              name="date_of_payment"
              type="date"
              value={formdueData.date_of_payment}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Start Date"
              fullWidth
              name="start_date"
              type="date"
              value={formdueData.start_date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: getMinStartDate(), // Disable past dates
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ fontWeight: "bold", textTransform: "none" }}
          startIcon={<CancelIcon />}
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ fontWeight: "bold", textTransform: "none" }}
          disabled={renwalloading}
          startIcon={<CheckCircleIcon />}
          onClick={handleSave}
          color="primary"
        >
          {renwalloading ? (
            <>
              <CircularProgress size={24} sx={{ marginRight: "8px" }} />
              Save
            </>
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>

<Snackbar
open={openToast}
autoHideDuration={6000}
onClose={handleToastClose}
anchorOrigin={{ vertical: "top", horizontal: "right" }}
>
<Alert
  variant="filled"
  onClose={handleToastClose}
  severity={snackbarSeverity}
>
  {snackbarMessage}
</Alert>
</Snackbar>
</>
  );
};

export default ChangePackage;
