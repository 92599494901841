import "./balansheet.css";
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import DateFilter from "./DateFilter.jsx";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { Card, Button, Grid, TextField, FormControl,Snackbar,Alert, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText } from '@mui/material';
import Menu from '@mui/material/Menu';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";
import { Link } from 'react-router-dom';
import {
  retrieveGymMember,
  AutocompleteMember,
  StatusMember,
  DeleteJoinMember,
} from "../Apihelper/MemberApiHelpr";

import {
  
  balanceallpaymentRetrive,
    AutocompleteBalance
  } from "../Apihelper/BalanceSheet.js";
  
  


import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function BalanceSheet(props) {
  const navigate = useNavigate();
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const dateRef = useRef();
  const [age, setAge] = React.useState('');
  const [hasMore, setHasMore] = useState(true);
  const [status, setstatus] = useState('');

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  const handleToggle = (id,status) => {
    setOpenConfirmation(true);
   const newStatus = status === 1 ? 0 : 1;
  setstatus(newStatus);
  setDeleteConfirmationId(id);
  setIsActive(newStatus === 0);

  };

  const handleConfirm = async() => {
    // setIsActive(!isActive);
    // setOpenConfirmation(false);

    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; 
     
      
        const res = await StatusMember(userToken,userId,status, deleteConfirmationId);

        if (res.code == "200") {
            handleSnackbarOpen(res.message, 'success');
            setIsActive(!isActive);
            setOpenConfirmation(false);
          //  setOpenDeleteConfirmation(false);
            fetchData();
        } else {
            handleSnackbarOpen(res.message, 'error');
            //  console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
  };
  const handleCancel = () => {
    setOpenConfirmation(false);
  };
 
 

 
  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
 
 
  const [options, setOptions] = useState([]);
  const [fetchdatarow, setetchdatarow] = useState([]); 
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'
  const [index, setIndex] = useState(0);
  const [datelist, setDatelist] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [todate, settodate] = useState("");
  const open = Boolean(anchorEl);
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleNavigate = (invoiceNumber) => {
    navigate('/invoice', { state: { invoice_number: invoiceNumber } });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchData = async (value = '') => {
    setLoading(true);
    setHasMore(true);
    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text
        const limit = 10; // Example limit
        const index = 0; // Example index
        const res = await balanceallpaymentRetrive(userToken, userId, searchText, limit, index, datelist,
          fromDate,
          "",);

        if (res.status == "200") {
            setetchdatarow(res.data)
            console.log(res.data);
            setLoading(false);
        } else {
            handleSnackbarOpen(res.message, 'error');
            // console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
};

const handleApplydatecustom = async ({ fromDateval, toDateval,value = '' }) => {
  settodate(toDateval);
  setFromDate(fromDateval);
  setLoading(true);
  try {
    const storedUserData = localStorage.getItem("userdata");
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const userId = userDataObj.id; // Assuming you have a userId in userdata
    const searchText = value; // Example search text
    const limit = 10; // Example limit
    const index = 0; // Example index
    setIndex(0);
    const res = await balanceallpaymentRetrive(
      userToken, 
      userId, 
      searchText, 
      limit,
       index,
      datelist,
      fromDateval,
      toDateval,
  
    );
    if (res.status == "200") {
      setetchdatarow(res.data)
      console.log(res.data);
      setLoading(false);
      if (res.data.length >= 0 && res.data.length <10) {
        setHasMore(false)
      }
      else {
        setHasMore(true);

      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  // Handle the selected value in the parent page
};
const handleApplydate = async (selectedValue,value = '') => {
  setDatelist(selectedValue);
  setLoading(true);
  try {
    const storedUserData = localStorage.getItem("userdata");
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const userId = userDataObj.id; // Assuming you have a userId in userdata
    const searchText = value; // Example search text
    const limit = 10; // Example limit
    const index = 0; // Example index
    const data = await balanceallpaymentRetrive(
      userToken, 
      userId, 
      searchText, 
      limit,
       index,
       selectedValue,
      "",
      "",
    );
    if (data.status == "200") {
      setLoading(false);
      setetchdatarow(() => [...data.data]);
    
    
      if ( data.data.length >= 0 && data.data.length <10) {
        setHasMore(false)
      }
      else {
        setHasMore(true);

      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  // Handle the selected value in the parent page
};
  useEffect(() => {
   
    fetchData();
}, []);

const handleLoadMore = async () => {
  // Calculate the next index based on the number of items loaded so far
  const nextIndex = fetchdatarow.length

  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);

  try {
    const userToken = userDataObj.token;
    const userId = userDataObj.id;
    const searchText = ''
    const limit = 10;

    const res = await balanceallpaymentRetrive(userToken, userId, searchText, limit, nextIndex,datelist,
      fromDate,
      "",);

    if (res.status == "200") {
      // Append the newly loaded data to the existing data
      setetchdatarow((prevResults) => [...prevResults, ...res.data]);

      // Check if there are more results available
      if (res.data.length >= 0 && res.data.length < 10) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const handleSearchChange = (event, value) => {
  const searchText = value || ''; // If value is undefined or empty, set searchText to empty string
  // setSearchText(searchText);
  // Call fetchData function here with the updated searchText
  fetchData(searchText);
};


const handleInputChange = async (value) => {
  if (value.length >= 3) {
  try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
    
      const res = await AutocompleteBalance(userToken, userId, searchText);

      if (res.code == "200") {
          const names = res.data.map(item => item.search_text);
          setOptions(names);
      } else {
          handleSnackbarOpen(res.message, 'error');
          //  console.error(error); // Handle error
      }
  } catch (error) {
      console.error(error);
      // Handle errors
  }
}
};
const handleDelete = (id) => {
  handleClose();
  setDeleteConfirmationId(id);
  setOpenDeleteConfirmation(true,id);
};

const handleConfirmDelete =async (id) => {
  // setSnackbarMessage('package deleted successfully');
  // setSnackbarSeverity('error');
  // setOpenDeleteConfirmation(false);
  // setOpenToast(true); // Show toast message

  if (deleteConfirmationId !== null) {
   
      try {
          const userData = localStorage.getItem("userdata");
          const userDataObj = JSON.parse(userData);
          const userToken = userDataObj.token;
          const userId = userDataObj.id; 
       
        
          const res = await DeleteJoinMember(userToken, userId, deleteConfirmationId);
  
          if (res.code == "200") {
              handleSnackbarOpen(res.message, 'success');
              setOpenDeleteConfirmation(false);
              fetchData()
          } else {
              handleSnackbarOpen(res.message, 'error');
              //  console.error(error); // Handle error
          }
      } catch (error) {
          console.error(error);
          // Handle errors
      }
  }
  };


const handleCancelDelete = () => {
  setOpenDeleteConfirmation(false);
};

const handleCardPress = async (id) => {
  try {
    navigate('/memberdetails', {
      state: {
        id: id,
        random_no: Math.random(),
      },
    });
  } catch (error) {
    console.error("Error:", error);
  }
};
React.useEffect(() => {
  const storedData = localStorage.getItem("userdata");
  if (!storedData) {
    // User is not logged in, no need to redirect
    navigate('/');
  }
  
}, []);
  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box  >
        <Header />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box >
               
              </Box>
              <Box>
              <Typography variant="h5" component="h6">
                  Balance Sheet
                </Typography>
              </Box>

            </Box>


            <Box sx={{ display: 'flex' }}>
              <Box>
                <Autocomplete
                  style={{ height: 50, }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={options}
                  onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
                  onChange={(event, value) => handleSearchChange(event, value)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      sx={{ width: 300 }}
                      {...params}
                      label="Search input"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />

                  )}
                  sx={{
                    '& .MuiInputBase-root': {


                      alignItems: 'center',
                      height: '50px',

                    },
                  }}
                />
              </Box>
            
              <Box>
                <FormControl >
                  <DateFilter ref={dateRef}
                   onApplycustomdate={handleApplydatecustom}
                   onApply={handleApplydate}
                  />
                </FormControl>
              </Box>
            </Box>


            <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        overflow: "hidden",
        margin: "20px 0",
      }}
    >
      {loading ? ( // Display spinner if loading is true
        <Box sx={{ textAlign: "center", padding: "50px 0" }}>
          <CircularProgress />
        </Box>
      ) : ( // Display table if loading is false
        <Table  sx={{ minWidth: 650 }}aria-label="premium balance sheet table" size="small" >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#2f80ed',
              }}
            >
              <TableCell
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                
                }}
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                
                }}
              >
                Invoice Number
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                  padding: "16px",
                }}
              >
                Member Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Mode of Payment
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
               
                }}
              >
                Amount
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                  
                }}
              >
                Payment Type
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchdatarow.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                  "&:hover": {
                    backgroundColor: "#e3f2fd",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell align="center"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
               <span
    onClick={() => handleNavigate(row.invoice_number)}
    style={{
      color: 'blue', // Change to your desired color
      cursor: 'pointer', // Pointer cursor to indicate it's clickable
      textDecoration: 'underline', // Optional: underline to indicate a link
    }}
  >
    {row.invoice_number}
  </span>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.cashPayment}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.onlinePayment}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.totalPayment}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.payment_type}
                </TableCell>
                <TableCell
  align="left"
  sx={{
    padding: "10px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  }}
>
  {new Date(row.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}
</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
             <Button
  variant="contained"
  color="primary"
  onClick={handleLoadMore}
 
  disabled={!hasMore || (fetchdatarow.length < 10 && fetchdatarow.length !== 0)}
  sx={{ fontWeight: 'bold', textTransform: 'none' }}
>
  {fetchdatarow.length === 0 || fetchdatarow.length < 10 ? 'No More Records' : 'Load More'}
</Button>


      </div>
          </div>

        </div>

      </Box>
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert  variant="filled" onClose={handleToastClose} severity={snackbarSeverity}>
        {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmation} onClose={handleCancel}>
        <DialogTitle>{isActive ? 'Deactivate' : 'Activate'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isActive
              ? 'Are you sure you want to deactivate this item?'
              : 'Are you sure you want to activate this item?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  sx={{fontWeight:'bold',textTransform:'none'}} startIcon={<CancelIcon />}onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm}sx={{fontWeight:'bold',textTransform:'none'}} variant="contained"startIcon={<CheckCircleIcon />} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteConfirmation} onClose={handleCancelDelete} fullWidth="sm">
      
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this Member?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<CancelIcon />} sx={{fontWeight:'bold',textTransform:'none'}} onClick={handleCancelDelete}>Cancel</Button>
        <Button onClick={handleConfirmDelete}  sx={{fontWeight:'bold',textTransform:'none'}} variant="contained" startIcon={<DeleteIcon />} color="error"> Delete</Button>
      </DialogActions>
    </Dialog>
    </Box>



  );
}

export default BalanceSheet;