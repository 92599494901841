export const balanceSheetRetrive = async (token, userId, searchText, limit, index,datelist, fromDate, toDate) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const payload = {
      user_id: userId,
      search_text: searchText,
      limit: limit,
      index: index,
      date: {
        type: "1",
        value: datelist, // Assuming datelist is defined correctly
      },
    };
    
    // Check if fromDate and toDate are not empty and modify the payload accordingly
    if (fromDate !== "" && toDate !== "") {
      payload.date = {
        type: "2",
        value: `${fromDate},${toDate}`, // Combine the two dates into a single string
      };
    }
    
    // Convert the payload object to a JSON string for the API call
    const raw = JSON.stringify(payload);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch(`${window.constants.api_url}/payment/balance-sheet`, requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error('Failed to retrieve user: ' + error.message);
    }
  };


  export const AutocompleteBalance = async (token, userId, searchText) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      search_text: searchText,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/payment/autocomplete-balance-sheet`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };

  export const balanceallpaymentRetrive = async (token, userId, searchText, limit, index,datelist, fromDate, toDate) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const payload = {
      user_id: userId,
      search_text: searchText,
      limit: limit,
      index: index,
      date: {
        type: "1",
        value: datelist, // Assuming datelist is defined correctly
      },
    };
    
    // Check if fromDate and toDate are not empty and modify the payload accordingly
    if (fromDate !== "" && toDate !== "") {
      payload.date = {
        type: "2",
        value: `${fromDate},${toDate}`, // Combine the two dates into a single string
      };
    }
    
    // Convert the payload object to a JSON string for the API call
    const raw = JSON.stringify(payload);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch(`${window.constants.api_url}/get/payment/summary`, requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error('Failed to retrieve user: ' + error.message);
    }
  };


