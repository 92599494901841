import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DuePaymentModal = ({
  open,
  onClose,
  onSubmit,
  dueAmount,
  memberId,
  paymentId,
}) => {
  const getCurrentDate = () => new Date(); // Return a Date object for the current date

  const [payingAmount, setPayingAmount] = useState(dueAmount || "");
  const [offer, setOffer] = useState(0);
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [dateOfPayment, setDateOfPayment] = useState(getCurrentDate()); // Initialize as Date object

  useEffect(() => {
    setPayingAmount(dueAmount);
  }, [dueAmount]);

  const handleSubmit = () => {
    // Format the date to 'yyyy-mm-dd' for submission
    const formattedDate =
      dateOfPayment instanceof Date
        ? dateOfPayment.toISOString().split("T")[0]
        : "";

    onSubmit({
      member_id: memberId,
      payment_id: paymentId,
      paying_amount: payingAmount,
      offer,
      mode_of_payment: modeOfPayment,
      date_of_payment: formattedDate,
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Due Payment Details</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date of Payment"
            value={dateOfPayment} // Use a Date object
            onChange={(newDate) => setDateOfPayment(newDate?.toDate() || getCurrentDate())}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" />
            )}
          />
        </LocalizationProvider>
        <TextField
          label="Paying Amount"
          fullWidth
          margin="normal"
          value={payingAmount}
          onChange={(e) => setPayingAmount(e.target.value)}
        />
        <TextField
          label="Offer"
          type="number"
          fullWidth
          margin="normal"
          value={offer}
          onChange={(e) => setOffer(Number(e.target.value))}
        />
        <TextField
          label="Mode of Payment"
          select
          fullWidth
          margin="normal"
          value={modeOfPayment}
          onChange={(e) => setModeOfPayment(e.target.value)}
        >
          <MenuItem value="phonepe">PhonePe</MenuItem>
          <MenuItem value="paypal">PayPal</MenuItem>
          <MenuItem value="credit_card">Credit Card</MenuItem>
          <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ fontWeight: "bold", textTransform: "none" }}
          startIcon={<CancelIcon />}
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ fontWeight: "bold", textTransform: "none" }}
          startIcon={<CheckCircleIcon />}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuePaymentModal;
