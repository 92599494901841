export const createAdmissionPackage = async (
    userToken,
    field1,
    field2,
    field3
  ) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
  
      const requestBody = {
        name: field1,
        duration: field2, // Assuming duration is a number
        package_amount: field3, // Assuming package_amount is a number
      };
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };
  
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/create`,
        requestOptions
      );
      const result = await response.json(); // Parse response as JSON
      return result;
    } catch (error) {
      throw new Error("Failed to create package: " + error.message);
    }
  };

  export const UpdateAdmissionPackage = async (
    id,
    userToken,
    name,
    duration,
    packageAmount
  ) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
  
      const requestBody = {
        admission_value_id: id,
        name: name,
        duration: parseInt(duration), // Convert to integer
        package_amount: parseFloat(packageAmount), // Convert to float
      };
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };
  
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/update`,
        requestOptions
      );
      const result = await response.json(); // Parse response as JSON
      return result;
    } catch (error) {
      throw new Error("Failed to create package: " + error.message);
    }
  };

  export const DeleteAdmissionPackage = async (token, userId, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      id: id,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/delete`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };

  export const retrieveAdmissionPackage = async (
    token,
    userId,
    searchText,
    limit,
    index
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      search_text: searchText,
      limit: limit,
      index: index,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/retrive`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };

  export const StatusAdmissionPackage = async (token, userId, status, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      id: id,
      status: status,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/active-inactive`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };

  export const AutocompleteAdmissionPackage = async (token, userId, searchText) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      search_text: searchText,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/autocomplete`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };


  export const PackageadmissionDropdown = async (token, userId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/dropdown`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };


export const PackageAdmissionDropdownvalue = async (token, userId, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      id: id,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/admission-charges/get/value`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };
  
  