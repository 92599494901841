import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createAdminUser } from "../Apihelper/ApiHelperUser";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useState } from "react";
import { useEffect, useRef } from "react";
import {
  Card,
  Snackbar,
  Alert,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Menu from "@mui/material/Menu";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";

import {
  retrieveOfferMember,
  offerPackageDropdown,
  createOfferPackageUser,
  AutoNonMembershipNumber,
  Nonregistervalue,
} from "../Apihelper/ApiOfferPackage";

import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function AddUser(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dateRef = useRef();

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [Dropdowndata, setDropdowndata] = useState([]); // Default to 'success'

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [UseradminData, setUseradminData] = useState({
    membershipnumber: "",
    fullname: "",
    email: "",
    phone: "",
    package: "",
    discount: "",
    payingamount: "",
    payableamount: "",
    dateofpayment: null,
  });
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseradminData({
      ...UseradminData,
      [name]: name === "email" ? value.toLowerCase() : value, // Ensure email is always lowercase
    });
  };
  
  

  const handleSubmit = async () => {
    // console.log(UseradminData);
    setSubmitted(true);
    // console.log(UseradminData);
    const requiredFields = [
      "fullname",
      "phone",
      "email",
      "package",
      "payableamount",
      "payingamount",
      "dateofpayment",
    ];
    const emptyFields = requiredFields.filter((field) => !UseradminData[field]);
    if (emptyFields.length > 0) {
      // Show error for empty fields

      return;
    }
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await createOfferPackageUser(userToken, UseradminData);
      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setUseradminData({
          membershipnumber: "",
          fullname: "",
          email: "",
          phone: "",
          package: "",
          discount: "",
          payingamount: "",
          payableamount: "",
          dateofpayment: null,
        });
        setTimeout(() => {
          navigate("/ParentOfferPackage");
        }, 1000);
      } else {
        handleSnackbarOpen(res.message, "error");
        //   console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  const fetchData = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await AutoNonMembershipNumber(userToken, userId);

      if (res.code == "200") {
        setUseradminData({ ...UseradminData, membershipnumber: res.data });
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const fetchDropdowndata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await offerPackageDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdowndata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchDropdowndata();
    fetchData();
  }, []);
  // const handlepayblevalueChange = async (e) => {
  //   // Update state with the selected package
  //   const selectedPackageId = e.target.value;
  //   setUseradminData({ ...UseradminData, package: selectedPackageId });
  // };
  const handlepayblevalueChange = async (e) => {
    // Update state with the selected package
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdowndata.find((row) => row.id === selectedPackageId);
  console.log(selectedPackage)
  setUseradminData({ ...UseradminData, package: selectedPackageId,packagename:selectedPackage?.name || '', });
  
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; 
      const res = await Nonregistervalue(userToken, userId, selectedPackageId);
  
      if (res.code == "200") {
      
          // Extract the package_amount from the first object in the array
          const packageAmount = res.data.value;
          setUseradminData(prevState => ({
            ...prevState,
            payableamount: packageAmount
          }));
         
        
          
        
      } else {
         // handleSnackbarOpen(res.message, 'error');
         console.error(error); 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error if necessary
    }
  };
  useEffect(() => {
    const payableAmount = parseFloat(UseradminData.payableamount) || 0;
    const discount = parseFloat(UseradminData.discount) || 0;

    // Calculate the paying amount
    const payingAmount = payableAmount - discount;

    // Update the paying amount in state
    setUseradminData((prevData) => ({
      ...prevData,
      payingamount: payingAmount >= 0 ? payingAmount : 0, // Ensure it's not negative
    }));
  }, [UseradminData.payableamount, UseradminData.discount]);

  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <Header />
      <CssBaseline />
      <Box
        component="main"
        sx={{ flexGrow: 1, overflowY: "auto", padding: "20px" }}
      > 

        <div style={{ display: "flex", flexGrow: 1, marginTop: "50px" }}>
          <div style={{ flex: 1, marginTop: "15px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Button
      variant="contained"
      startIcon={<ArrowBackIcon />}
      sx={{
        textTransform: "none",
        fontWeight: 700,
        marginRight: 2, // Adjust this value as needed
      }}
      onClick={() => navigate(-1)}
    >
      Go Back
    </Button>
    <Typography variant="h6" component="h6">
      ADD NON-MEMBER
    </Typography>
  </Box>
  
  <Button
  
    variant="contained"
    sx={{
      textTransform: "none",
      whiteSpace: "nowrap",
      fontWeight: 700,
    }}
    onClick={() => navigate("/ParentOfferPackage")}
  >
    View List
  </Button>
</Box>


            <Card sx={{ marginTop: "20px", marginBottom: "20px" }}>
        
              <Grid
                container
                spacing={3}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="membershipnumber"
                    label=" Membership number"
                    fullWidth
                    placeholder=" Enter Membership number"
                    inputProps={{ readOnly: true }}
                    value={UseradminData.membershipnumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="fullname"
                    label="Name"
                    fullWidth
                    placeholder=" Enter Name"
                    value={UseradminData.fullname}
                    onChange={handleChange}
                    error={submitted && !UseradminData.fullname}
                    helperText={
                      submitted &&
                      !UseradminData.fullname &&
                      "Name  is required"
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="phone"
                    label="Phone"
                    type="number"
                    fullWidth
                    placeholder="Enter Phone..."
                    value={UseradminData.phone}
                    onChange={handleChange}
                    error={submitted && !UseradminData.phone}
                    helperText={
                      submitted &&
                      !UseradminData.phone &&
                      "phone No is required"
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    name="email"
                    label="Email"
                    fullWidth
                    placeholder=" Enter Email"
                    value={UseradminData.email}
                    onChange={handleChange}
                    error={submitted && !UseradminData.email}
                    helperText={
                      submitted && !UseradminData.email && "email  is required"
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="package-label"> Package</InputLabel>
                    <Select
                      labelId="package-label"
                      id="package"
                      label="Package"
                      value={UseradminData.package}
                      onChange={handlepayblevalueChange}
                      placeholder="choose your Package..."
                      fullWidth
                      error={submitted && !UseradminData.package}
                      helperText={
                        submitted &&
                        !UseradminData.package &&
                        "package  is required"
                      }
                    >
                      {Dropdowndata.map((row) => (
                        <MenuItem value={row.id}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                <TextField
                    label="Payable Amount"
                    fullWidth
                    value={UseradminData.payableamount}
                    error={submitted && !UseradminData.payableamount}
                    helperText={
                      submitted &&
                      !UseradminData.payableamount &&
                      "Payable Amount  is required"
                    }
                    onChange={(e) =>
                      setUseradminData({
                        ...UseradminData,
                        payableamount: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingX: "20px",
                }}
              >
                <Grid item xs={4}>
                 
                   <TextField
                    label="Discount / Offer"
                    fullWidth
                    placeholder="Discount / Offer"
                    value={UseradminData.discount}
                    onChange={(e) =>
                      setUseradminData({
                        ...UseradminData,
                        discount: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Paying Amount"
                    fullWidth
                    placeholder=" Paying Amount"
                    value={UseradminData.payingamount}
                    error={submitted && !UseradminData.payingamount}
                    helperText={
                      submitted &&
                      !UseradminData.payingamount &&
                      "Paying Amount  is required"
                    }
                    onChange={(e) =>
                      setUseradminData({
                        ...UseradminData,
                        payingamount: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem components={["DatePicker"]}>
                        <DatePicker
                          value={UseradminData.dateofpayment}
                          onChange={(date) =>
                            setUseradminData({
                              ...UseradminData,
                              dateofpayment: date,
                            })
                          } // Update 'dob' state in formData
                          label="Date of Payment"
                          format="DD/MM/YYYY"
                          clearable
                          slotProps={{
                            textField: {
                              error: submitted && !UseradminData.dateofpayment,
                              helperText:
                                submitted &&
                                !UseradminData.dateofpayment &&
                                "Date of Payment is required",
                            },
                          }}
                        />
                      </DemoItem>
                    </LocalizationProvider>
                  </Box>{" "}
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                  type="reset"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<CheckCircleIcon />}
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Add Member
                </Button>
              </Box>
            </Card>
          </div>
        </div>
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AddUser;
