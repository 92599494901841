import React, { useState ,useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  loginUser,
} from "./Apihelper/ApiHelper.js";

// TODO remove, this demo shouldn't need to reset the theme.


export default function SignIn() {
  const navigate = useNavigate(); // Use useNa
  const [toastmessage, settoastmessage] = useState('');
  const [toastcolor, settoastcolor] = useState('info');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }

      setSnackbarOpen(false);
  };
  const handleForgotPasswordClick = () => {
    navigate('/forgotpassword'); // Navigate to '/forgotpassword' route
};

    const initialValues = {
    email: '',
    password: '',
  };
  
  const handleSubmit = async (values, { setSubmitting }) => {
        setLoading(true); // Set loading state to true while waiting for the API response
        setSubmitting(false);
    try {
      // Create a FormData object and append email and password
      const userData = new FormData();
      userData.append("email", values.email);
      userData.append("password", values.password);
  
      // Call loginUser function from your API helper with the FormData object
      const res = await loginUser(userData);
  
      // Check the response from the API
      if (res.code == "200") {
     
        setLoading(false); 
        localStorage.setItem('userdata', JSON.stringify(res.admin));
      
     navigate('/dashboard', { state: { showSnackbar: true } });

     settoastmessage('Login Successful ! Welcome Back!');
     setSnackbarOpen(true);
     
      } else if(res.code == "500") {
        // If login fails, display error message in snackbar
        
        settoastmessage(res.message);
        settoastcolor('error');
        setLoading(false); 
        setSnackbarOpen(true);
      }else{
        
        settoastmessage(res.message);
        settoastcolor('error');
        setLoading(false); 
        setSnackbarOpen(true);
      }
    } catch (error) {
      // If an error occurs during the API request, log the error
      console.error("Error:", error);
      settoastmessage(error.message);
    
      setSnackbarOpen(true);
      setLoading(false); 
    } 
  };
 

  return (
    <Box sx={{
      position: "relative",
      display: "flex",
      minHeight: "100vh",
      backgroundColor:'#E0E0E0' ,
      backgroundSize: "300% 300%",
      animation: "backgroundAnimation 10s ease infinite",
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffffff",
      fontSize: "24px",
      fontWeight: "bold",
    }}>
          <div className="firecrackers"></div>
          <style>
        {`
          @keyframes backgroundAnimation {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }

          .firecrackers {
            position: absolute;
            width: 100%;
            height: 100%;
            pointer-events: none;
            top: 0;
            left: 0;
            animation: firecrackerBurst 3s ease-in-out infinite;
          }

          @keyframes firecrackerBurst {
            0% {
              transform: scale(0);
              opacity: 0;
            }
            50% {
              transform: scale(1.5);
              opacity: 1;
            }
            100% {
              transform: scale(0);
              opacity: 0;
            }
          }

          .firecrackers::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #ffdf00;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            animation: firecracker1 1.5s linear infinite;
          }

          .firecrackers::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #ff6b6b;
            border-radius: 50%;
            top: 60%;
            left: 60%;
            animation: firecracker2 1.5s linear infinite;
          }

          @keyframes firecracker1 {
            0% {
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              transform: translate(-50%, -50%) scale(2);
            }
            100% {
              transform: translate(-50%, -50%) scale(1);
            }
          }

          @keyframes firecracker2 {
            0% {
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              transform: translate(-50%, -50%) scale(3);
            }
            100% {
              transform: translate(-50%, -50%) scale(1);
            }
          }
        `}
      </style> <style>
        {`
          @keyframes backgroundAnimation {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }

          .firecrackers {
            position: absolute;
            width: 100%;
            height: 100%;
            pointer-events: none;
            top: 0;
            left: 0;
            animation: firecrackerBurst 3s ease-in-out infinite;
          }

          @keyframes firecrackerBurst {
            0% {
              transform: scale(0);
              opacity: 0;
            }
            50% {
              transform: scale(1.5);
              opacity: 1;
            }
            100% {
              transform: scale(0);
              opacity: 0;
            }
          }

          .firecrackers::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #ffdf00;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            animation: firecracker1 1.5s linear infinite;
          }

          .firecrackers::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #ff6b6b;
            border-radius: 50%;
            top: 60%;
            left: 60%;
            animation: firecracker2 1.5s linear infinite;
          }

          @keyframes firecracker1 {
            0% {
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              transform: translate(-50%, -50%) scale(2);
            }
            100% {
              transform: translate(-50%, -50%) scale(1);
            }
          }

          @keyframes firecracker2 {
            0% {
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              transform: translate(-50%, -50%) scale(3);
            }
            100% {
              transform: translate(-50%, -50%) scale(1);
            }
          }
        `}
      </style>
      <Container component="main" maxWidth="xs"  sx={{ marginTop: '68px',}}>
        <CssBaseline />
        <Box
          sx={{
           
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Box shadow
            borderRadius: '10px',
          }}
        >
         
         
            <img src='rectangle-9@2x.png' width={100}/>
    
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box >
          <Formik
                            initialValues={initialValues}
                            // validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                                                        {({ values }) => (

            <Form >
 <Field as={TextField}
              margin="normal"
            
              fullWidth
             
              label="Email Address"
              name="email"
           
              InputLabelProps={{ shrink: true }}
            />
           <Field as={TextField}
              margin="normal"
            
              fullWidth
              name="password"
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,fontWeight:"bold"}}
               disabled={loading}>  {loading &&  <CircularProgress
                size={16}
                style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }}
            />}
            
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" onClick={handleForgotPasswordClick}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            </Form> )}
        </Formik>
          </Box>
        </Box>
       
      </Container>
      <Snackbar
            mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: toastcolor === 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
    </Box>
  );
}