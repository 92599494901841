// Dashboard.js

import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Header from '../Includes/Header';
import Sidebar from '../Includes/Sidebar';
import GraphChart from './GraphChart.jsx';
import BarChart from './BarChart.jsx';
import { useNavigate } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container,TablePagination, CssBaseline,Backdrop,CircularProgress, Grid, Typography, Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Stepper, Step, StepLabel,Button } from '@mui/material';
import PieChart from './PieChart.jsx';
import Radarchart from './Radarchart.jsx';

// import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
const drawerWidth = 240;
import {
  DashboardCount,
  MemberExpireDUserauto
} from "../Apihelper/Dashboard.js";
const steps = ['Page 1', 'Page 2']; // Define your steps here
const useCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  height: '95px', // Consistent height for all cards
  borderRadius: '12px',
  textAlign: 'center', // Center align all text within the card
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundImage: 'linear-gradient(135deg, #F2E1C1, #F9C8A0)', // Example of a creamy gradient
  transition: 'transform 0.3s, box-shadow 0.3s, background-image 0.5s', // Added transition for background image
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
    backgroundImage: 'linear-gradient(135deg, #F5D0A9, #F9C8A0)', // Gradient changes on hover
  },
};

const useValueStyles = {
  fontSize: '16px', // Smaller font size for the values
  color: '#555',
};
const useTypographyStyles = {
  fontSize: '12px', // Smaller font size for the titles
  fontWeight: 'bold',
  color: '#333',
};
const useTableStyles = {
  borderRadius: '10px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  marginTop: '10px',
};

const useTableHeadStyles = {
  backgroundColor: '#5cabeb',
};

const useTableCellStyles = {
  fontWeight: 'bold',
  color: '#fff',
  textAlign: 'center',
};

const useTableBodyCellStyles = {
  padding: '12px',
  textAlign: 'center',
  color: '#333',
  fontSize: '14px',
};

const useTableRowStyles = {
  '&:hover': {
    backgroundColor: '#f5f5f5',
    transition: 'background-color 0.3s',
  },
};
const Dashboard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  // Fetch function to get dashboard details
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userdata"));
        const { token, id: userId } = userData;
        const data = await DashboardCount(token, userId);
        if (data.code === 200) {
          setDashboardData(data);
        } else {
          setError(data.message || 'Failed to fetch dashboard data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);
  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleNavigateToNext = () => {
    navigate('/dashboard-next');
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (loading) return  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
  <CircularProgress color="inherit" />
</Backdrop>;
  if (error) return <Typography color="error">{error}</Typography>;


  const cardStyle = {
    backgroundColor: '#5cabeb',
    borderRadius: '6px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '1rem',
    marginTop: '5px',
    color: '#606060',
    fontSize: '14px'
  };
 
  const dummyData = [
    { id: 1, name: 'John Doe', amount: 25, exp_dt: '23 Jan 2024', phone: '9876543210', image: <AccountCircleIcon /> },
    { id: 2, name: 'Jane Doe', amount: 30, exp_dt: '23 Jan 2024', phone: '9876543210', image: <AccountCircleIcon /> },
    { id: 3, name: 'Jane Doe', amount: 30, exp_dt: '23 Jan 2024', phone: '9876543210', image: <AccountCircleIcon /> },
    { id: 4, name: 'Jane Doe', amount: 30, exp_dt: '23 Jan 2024', phone: '9876543210', image: <AccountCircleIcon /> },
    { id: 5, name: 'Jane Doe', amount: 30, exp_dt: '23 Jan 2024', phone: '9876543210', image: <AccountCircleIcon /> },
    // Add more rows as needed
    { id: 6, name: 'Jane Doe', amount: 30, exp_dt: '23 Jan 2024', phone: '9876543210', image: <AccountCircleIcon /> },
 
  ];
  return (

    <Box sx={{ display: 'flex', height: '100vh',   backgroundImage: 'linear-gradient(45deg, #6a11cb, #2575fc, #4facfe, #00f260)',
      backgroundSize: '200% 200%',
      animation: 'gradientBG 10s ease infinite', }}>
 <style>
        {`
          @keyframes gradientBG {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
        `}
      </style>
      <CssBaseline />
      <Box  >
        <Header />
      </Box>

      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', }}>

        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
       
            <Box>
              <Typography
              sx={{fontSize:'28px',fontWeight:'bold',margin:'10px 0px'}}
              >Dashboard</Typography>
            </Box>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} sx={{ gridAutoFlow: "dense" }}>
              
              <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
          {[
            { title: "Members", value: dashboardData.members },
            { title: "Active Members", value: dashboardData.active_members },
            { title: "Total Employees", value: dashboardData.employees },
            { title: "New Employees", value: dashboardData.employees },
            { title: "Total Payments", value: dashboardData.total_payments },
            { title: "PT Payments", value: dashboardData.trainer_package_payments },
            { title: "Package Payments", value: dashboardData.main_package_payments },
            { title: "Monthly Package Payments", value: dashboardData.main_package_monthly_payments },
            { title: "Monthly PT Payments", value: dashboardData.trainer_package_monthly_payments },
            { title: "Total Month Payments", value: dashboardData.total_month_payments },
            { title: "Today Package Payments", value: dashboardData.today_main_package_payments },
            { title: "Today PT Payments", value: dashboardData.today_trainer_package_payments },
            { title: "Today Total Payments", value: dashboardData.today_total_payments },
            { title: "Total Due", value: dashboardData.total_due },
          ].map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={useCardStyles}>
                <CardContent>
                  <Typography variant="h6" sx={useTypographyStyles}>{item.title}</Typography>
                  <Typography variant="h6"  sx={useValueStyles}>{item.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
              </Grid>
           
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Card style={{ marginBottom: '16px',display:"flex" }}>
                      <CardContent style={{ padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                          Total Invoice
                        </Typography>
                        <PieChart />
                      </CardContent>
                      <CardContent >
                        <Typography variant="h6" gutterBottom>
                          Due Amount
                        </Typography>
                        <Radarchart />
                      </CardContent>
                    </Card>
                  </Grid>
                 
                </Grid>
                
               
                <Card sx={{ marginTop: '10px' }}>
                                   <CardContent style={{ padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                          INCOME Trail
                        </Typography>
                        <BarChart />
                      </CardContent>
                  {/* <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow sx={useTableHeadStyles}>
                          <TableCell sx={useTableCellStyles}>Sl. No.</TableCell>
                          <TableCell sx={useTableCellStyles}>Image</TableCell>
                          <TableCell sx={useTableCellStyles}>Name</TableCell>
                          <TableCell sx={useTableCellStyles}>Mid</TableCell>
                          <TableCell sx={useTableCellStyles}>Phone No.</TableCell>
                          <TableCell sx={useTableCellStyles}>Due Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dummyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                          <TableRow key={row.id} sx={useTableRowStyles}>
                            <TableCell sx={useTableBodyCellStyles}>{page * rowsPerPage + index + 1}</TableCell>
                            <TableCell sx={useTableBodyCellStyles}>{row.image}</TableCell>
                            <TableCell sx={useTableBodyCellStyles}>{row.name}</TableCell>
                            <TableCell sx={useTableBodyCellStyles}>{row.amount}</TableCell>
                            <TableCell sx={useTableBodyCellStyles}>{row.phone}</TableCell>
                            <TableCell sx={useTableBodyCellStyles}>{row.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dummyData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </Card>
                
              </Grid>
              
            </Grid>
        
            <Grid item xs={12} md={6} sx={{mt:2}}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                  color="inherit"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  Back
                </Button>
                
                <Button
                  variant="contained"
                  onClick={handleNavigateToNext}
                >
               <Typography sx={{fontWeight:"bold"}}> Go to Next Page</Typography>  
                </Button>
              </Box>
           
            </Grid>
          </div>
        </div>
      </Box>
    </Box>


  );
};

export default Dashboard;
