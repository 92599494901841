import React, { useState, useEffect,useCallback } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
} from "react-router-dom";
import Login2 from "./pages/Login2";
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardNext from './pages/Dashboard/DashboardNext';
import MemberList from './pages/MemberList/MemberList';
import NewMember from './pages/MemberList/NewMember';
import Payment from "./pages/Payment/Payment";
import AddUser from "./pages/User/AddUser";
import AddEmployees from "./pages/Employees/AddEmployees";
import InvoicePage from './pages/Invoices/Invoice';
import UserList from "./pages/User/UserList";
import MemberDetails from "./pages/MemberList/MemberDetails";
import EmployeesDetail from "./pages/Employees/EmployeesDetail";
import ListDueUsers from "./pages/ListDueUsers/ListDueUsers";
import ManagePackage from "./pages/ManagePackage/ManagePackage";
// import OfferPackage from "./pages/OfferPackage/OfferPackage";
import ParentOfferPackage from "./pages/OfferPackage/ParentOfferPackage";
import AddOfferPackage from "./pages/OfferPackage/AddOfferPackage";
import ForgetPassword from './pages/ForgotPassword.jsx';
import ResetPassword from './pages/ConfirmPassword.jsx';
import AddNonMember from "./pages/OfferPackage/AddNonMember";
import BalanceSheet from "./pages/BalanceSheet/BalanceSheet.jsx";
import AllPayment from "./pages/BalanceSheet/AllPayment.jsx";
import FollowUP from "./pages/FollowUps/FollowUP.jsx";
import { PathProvider } from './pages/Includes/PathContext';
function App() {
  const AUTO_LOGOUT_TIME = 8 * 60 * 60 * 1000; // 8 hours in milliseconds

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const ASSET_PATH = process.env.PUBLIC_URL;
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("userdata");
    return storedData ? JSON.parse(storedData) : null;
  });

  const checkUserData = () => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      return <Navigate to="/" />;
    }
    setUserData(JSON.parse(storedData));
    return null;
  };
  window.constants = {
 //api_url: "http://127.0.0.1:8000/api", //local
  api_url: "https://mtt-mg.examot.com/api",  //live
  asset_path: ASSET_PATH,
      // base_path:'https://54.236.17.116/deep-delve-hindalco/' //live
    };
    useEffect(() => {
      const storedData = localStorage.getItem("userdata");
      if (!storedData) {
        // User is not logged in, no need to redirect
        return;
      }
  
      setUserData(JSON.parse(storedData));
  
      // If the user is logged in and tries to access the login page, redirect to the dashboard
      // if (window.location.pathname === '/' && window.location.search !== '?logout=true') {
      //   window.location.replace('/query');
      // }
    }, []);
    const PrivateRoute = ({ element }) => {
      return userData ? element : checkUserData();
    };
    const handleLogout = useCallback(() => {
      localStorage.removeItem("userdata");
      setUserData(null);
      window.location.href = "/"; // Redirect to login
    }, []);
  
    // Auto-logout timer management
    useEffect(() => {
      // Function to reset the timeout
      const resetTimeout = () => {
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(handleLogout, AUTO_LOGOUT_TIME);
      };
  
      // Add event listeners for user activity
      window.addEventListener("mousemove", resetTimeout);
      window.addEventListener("keydown", resetTimeout);
      window.addEventListener("scroll", resetTimeout);
      window.addEventListener("click", resetTimeout);
  
      // Initialize timeout
      let logoutTimer = setTimeout(handleLogout, AUTO_LOGOUT_TIME);
  
      // Cleanup on component unmount
      return () => {
        clearTimeout(logoutTimer);
        window.removeEventListener("mousemove", resetTimeout);
        window.removeEventListener("keydown", resetTimeout);
        window.removeEventListener("scroll", resetTimeout);
        window.removeEventListener("click", resetTimeout);
      };
    }, [handleLogout]);
  return (
    <PathProvider>
    <Routes>
      
      <Route path="/" element={<Login2 />} />
      <Route
                path="/reset-password"
                element={<ResetPassword />}
              />
                <Route
                path="/forgotpassword"
                element={<ForgetPassword />}
              />
      <Route
                path="/dashboard"
                element={<PrivateRoute element={<Dashboard />} />}
              />

      <Route
                path="/dashboard-next"
                element={<PrivateRoute element={<DashboardNext />} />}
              />
                <Route
                path="/Followup-visitors"
                element={<PrivateRoute element={<FollowUP />} />}
              />
                 <Route
                path="/memberList"
                element={<PrivateRoute element={<MemberList />} />}
              />
              
                <Route
                path="/balance-sheet"
                element={<PrivateRoute element={<BalanceSheet />} />}
              />
               <Route
                path="/all-payment-bydate"
                element={<PrivateRoute element={<AllPayment />} />}
              />
                <Route
                path="/invoice"
                element={<PrivateRoute element={<InvoicePage />} />}
              />
    <Route
                path="/newmember"
                element={<PrivateRoute element={<NewMember />} />}
              />
     <Route
                path="/memberdetails"
                element={<PrivateRoute element={<MemberDetails />} />}
              />
              
      <Route
                path="/userlist"
                element={<PrivateRoute element={<UserList />} />}
              />
   
   <Route
                path="/adduser"
                element={<PrivateRoute element={<AddUser />} />}
              /><Route
              path="/addemployees"
              element={<PrivateRoute element={<AddEmployees />} />}
            />

       <Route
                path="/payment"
                element={<PrivateRoute element={<Payment />} />}
              />
     <Route
                path="/listdueusers"
                element={<PrivateRoute element={<ListDueUsers />} />}
              />
                <Route
                path="/managepackage"
                element={<PrivateRoute element={<ManagePackage />} />}
              />
                <Route
                path="/ParentOfferPackage"
                element={<PrivateRoute element={<ParentOfferPackage />} />}
              />
               <Route
                path="/addnonmember"
                element={<PrivateRoute element={<AddNonMember />} />}
              />
                <Route
                path="/addofferpackage"
                element={<PrivateRoute element={<AddOfferPackage />} />}
              />
              
                <Route
                path="/employeesdetail"
                element={<PrivateRoute element={<EmployeesDetail />} />}
              />
              
    
    </Routes>
    </PathProvider>
  );
}
export default App;
